<template>
  <div id="VRKPage">
    <div class="FirstBlock" style="z-index: 100;">
      <LandingNavbar
        style="z-index: 100;"
        :SendRequestBusiness="
          () => {
            showRegistrationModal = true;
          }
        "
      />

      <div class="Page-Header">
        <span class="Page-Header-Title"
          >Конкурс ВКР<span class="P-H-T-Star">*</span> с использованием
          1С</span
        >

        <span class="Page-Header-Desc"
          >Ежегодно "1С" проводит международный конкурс выпускных
          квалификационных работ (ВКР) для студентов, обучающихся как по ИТ-,
          так и по не-ИТ-специальностям.</span
        >

        <a href="https://forms.gle/B4XNY8GbQyh9BmTx8" target="_blank">
          <ButtonGradientInsideElement
            class="NewStyledButton"
            ButtonText="Стать лучшим в своей сфере!"
          />
        </a>

        <span class="Page-Header-Add-Info"
          >*Выпускная квалификационная работа</span
        >

        <div class="Backgrounds-Collection">
          <div class="Header-BG-Light"></div>
        </div>
      </div>

      <div class="Page-Opportunities">
        <span class="Page-Opportunities-Title">Возможности</span>

        <div class="P-O-Block">
          <div class="P-O-B-Line Line1">
            <div class="P-O-B-Wrapper">
              <span class="P-O-B-Text"
                >Станьте частью команды 1С и получите доступ к 1С для учебных
                заведений
              </span>
            </div>

            <div class="P-O-B-Wrapper">
              <span class="P-O-B-Text"
                >Прокачайте своё портфолио, чтобы стать лучшими на рынке труда
              </span>
            </div>
          </div>
          <div class="P-O-B-Line Line2">
            <div class="P-O-B-Wrapper">
              <span class="P-O-B-Text"
                >Продемонстрируйте свои знания и навыки в работе с 1С
              </span>
            </div>

            <div class="P-O-B-Wrapper">
              <span class="P-O-B-Text"
                >Получите шанс выиграть до 300 000 рублей</span
              >
            </div>
          </div>

          <div></div>
        </div>

        <div class="Backgrounds-Collection">
          <div class="Header-Right-BG-Light"></div>
        </div>
      </div>
    </div>

    <div class="AwardsBlock">
      <div class="Special-Awards">
        <span class="Special-Awards-Title">Этапы конкурса</span>

        <div class="Special-Awards-List">
          <div class="S-A-L-Block">
            <div class="Awards-Icon"></div>
            <span class="Awards-Text" lang="ru">Региональный этап</span>
          </div>

          <div class="S-A-L-Block">
            <div class="Awards-Icon"></div>
            <span class="Awards-Text" lang="ru"
              >По федеральным округам и зарубежным странам
            </span>
          </div>

          <div class="S-A-L-Block">
            <div class="Awards-Icon"></div>
            <span class="Awards-Text" lang="ru">Заключительный</span>
          </div>
        </div>
      </div>

      <div class="Earn-Money">
        <span class="Earn-Money-Title">Получите награды</span>

        <div class="Earn-Money-Card">
          <div class="E-M-C-Places">
            <div class="E-M-C-P-Block">
              <span class="E-M-C-P-B-Title">1 место</span>
              <span class="E-M-C-P-B-Desc"
                >300 000<br /><span class="Money">рублей</span></span
              >
            </div>
            <div class="E-M-C-P-Block">
              <span class="E-M-C-P-B-Title">2 место</span>
              <span class="E-M-C-P-B-Desc"
                >200 000<br /><span class="Money">рублей</span></span
              >
            </div>
            <div class="E-M-C-P-Block">
              <span class="E-M-C-P-B-Title">3 место</span>
              <span class="E-M-C-P-B-Desc"
                >100 000<br /><span class="Money">рублей</span></span
              >
            </div>
          </div>

          <div class="E-M-C-Bottom-Button">
            <span class="E-M-C-Desc"
              >Команда Октагона поможет тебе подать заявку на участие в
              конкурсе!</span
            >

            <a
              href="https://forms.gle/B4XNY8GbQyh9BmTx8"
              class="Money-Card-Style"
              target="_blank"
            >
              <ButtonGradientInsideElement
                style="width: 100%"
                ButtonText="Хочу участвовать в конкурсе"
              />
            </a>
          </div>

          <div class="E-M-C-Extra-BG"></div>
        </div>
      </div>

      <div class="Backgrounds-Collection">
        <div class="Awards-Left-Light"></div>
        <div class="Awards-Right-Light"></div>
      </div>
    </div>

    <div class="Become-Participant">
      <span class="Become-Participant-Title">Принять участие - легко!</span>

      <div class="Become-Participant-Blocks">
        <div class="B-P-B-Element">
          <div class="B-P-B-E-Img EImage1"></div>
          <div class="B-P-B-E-Texts">
            <span class="B-P-B-E-T-Title">ВКР с 1С</span>
            <span class="B-P-B-E-T-Desc"
              >Выполните ВКР с использованием 1С</span
            >
          </div>
        </div>
        <div class="B-P-B-Element">
          <div class="B-P-B-E-Img EImage2"></div>
          <div class="B-P-B-E-Texts">
            <span class="B-P-B-E-T-Title">Регистрация</span>
            <span class="B-P-B-E-T-Desc"
              >Регистрация на сайте до
              <span class="Bold">15 сентября 2024 года.</span></span
            >
          </div>
        </div>
        <div class="B-P-B-Element">
          <div class="B-P-B-E-Img EImage3"></div>
          <div class="B-P-B-E-Texts">
            <span class="B-P-B-E-T-Title">Загрузить ВКР</span>
            <span class="B-P-B-E-T-Desc"
              >Загрузите необходимые материалы на сайт
            </span>
          </div>
        </div>
        <div class="B-P-B-Element">
          <div class="B-P-B-E-Img EImage4"></div>
          <div class="B-P-B-E-Texts">
            <span class="B-P-B-E-T-Title">Результаты</span>
            <span class="B-P-B-E-T-Desc">Ожидайте результаты </span>
          </div>
        </div>
      </div>

      <div class="Become-Participant-Results">
        <span class="Become-Participant-Results-Info">
          <span style="color: white"
            >Итоги конкурса будут подведены в несколько этапов:</span
          ><br />
          Региональный тур: до октября 2024 года.<br />
          Финальный тур: ноябрь 2024 года.<br />
        </span>
      </div>

      <a href="https://forms.gle/B4XNY8GbQyh9BmTx8" target="_blank">
        <ButtonGradientInsideElement
          class="BeParticipantButton"
          ButtonText="Принять участие"
        />
      </a>

      <div class="Backgrounds-Collection">
        <div class="Participant-Light"></div>
        <div class="Participant-Octagon-Left-Image"></div>
        <div class="Participant-Octagon-Right-Image"></div>
      </div>
    </div>

    <div class="OurStudents">
      <span class="OS-Title">Наши студенты</span>
      <div class="OS-List">
        <div
          class="OS-Card Card"
          v-for="(feed, findx) in FeedbacksList"
          :key="`${findx}${feed.FLName}`"
        >
          <div class="Info">
            <div class="IMG" :style="getFeedbackIMG(feed.IMGName)"></div>
            <div class="Participant">
              <span class="NameSurname">{{ feed.FLName }}</span>
              <span class="ShortPInfo">{{ feed.From }}</span>
            </div>
          </div>
          <span class="Text" v-html="feed.Text"></span>
        </div>
      </div>

      <div class="OS-Light"></div>
    </div>

    <div class="ParticipantTwo">
      <span class="PT-Title"
        >Не упустите шанс проявить себя и сделать шаг к успешной карьере!</span
      >

      <span class="PT-Desc">
        Подробную информацию о конкурсе, а также правила участия и формы
        регистрации можно найти на сайте:
        <a href="https://1c.ru/diplomprojects/" target="_blank" class="Link"
          >https://1c.ru/diplomprojects/</a
        >
      </span>

      <a
        href="https://forms.gle/B4XNY8GbQyh9BmTx8"
        target="_blank"
        class="PT-Button BlueButton noselect"
        >Принять участие</a
      >
    </div>

    <div class="JoinOctagon">
      <span class="JO-Title">Присоединяйтесь к Октагону</span>

      <span class="JO-Desc">
        С нами интересно, полезно, перспективно!
      </span>

      <a
        href="https://t.me/forus_octagon"
        target="_blank"
        class="JO-Button BlueButton noselect"
        >Перейти в Telegram</a
      >
    </div>

    <div class="Backgrounds-Collection">
      <div class="Header-BG"></div>
      <div class="Header-Top-Left"></div>
      <div class="Header-Top-Right"></div>
      <div class="Page-Bottom-Light"></div>
    </div>

    <RegistrationModal
      style="z-index: 200;"
      @sendCloseRegistrationModal="sendCloseRegistrationModal"
      :openOrgRegistration="openOrgRegistration"
      :showModal="showRegistrationModal"
    />

    <SendRequest
      style="z-index: 250;"
      @sendFalseToParent="sendFalseToParent"
      :key="`${sendFalseToParent}${setTypeForModal}`"
      :setType="setTypeForModal"
      :showModal="showRequestModal"
    />
  </div>
</template>

<script>
import LandingNavbar from "@/components/Landing/Navbar";
import ButtonGradientInsideElement from "@/components/CustomElements/ButtonGradient";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SendRequest from "@/components/Landing/SendRequest";
import RegistrationModal from "@/components/Landing/RegistrationModal";

export default {
  components: {
    LandingNavbar,
    ButtonGradientInsideElement,
    RegistrationModal,
    SendRequest,
  },
  data() {
    return {
      FeedbacksList: [
        {
          IMGName: "DimaG",
          FLName: "Дмитрий Гусаков",
          From: "Выпускник, г. Иркутск",
          Text: `«Изначально я пришёл в «Октагон» со своим<br/>
            дипломным проектом «Web-приложение йога-<br/>
            клуб».<br/>
            После реализации проекта мне предложили<br/>
            стажировку, и так как на практике я уже имел<br/>
            опыт взаимодействия с компанией и с людьми,<br/>
            которые здесь работают, у меня получилось<br/>
            быстро адаптироваться и закончить<br/>
            стажировку досрочно.<br/>
            <br/>
            Теперь я работаю на должности инженер-<br/>
            программист.<br/>
            Всем тем, кто собирается проходить практику в<br/>
            «Октагоне» желаю не бояться трудностей, ведь<br/>
            команда «Октагона» всегда поможет вам в<br/>
            реализации ваших проектов.»`,
        },
        {
          IMGName: "ViktoriaB",
          FLName: "Виктория Бубнова",
          From: "Выпускница, г. Иркутск",
          Text: `«Мой проект был связан с походной<br/>
            деятельностью – приложение для<br/>
            планирования походов как для любителей, так<br/>
            и для профессионалов. Было разработано<br/>
            приложение с адаптивной вёрсткой, в котором<br/>
            можно было создать свой поход, найти поход<br/>
            по интересам. Всем участникам «Октагона»<br/>
            желаю удачи и не бояться и быть смелыми в<br/>
            своих идеях.»`,
        },
        {
          IMGName: "SergeiB",
          FLName: "Сергей Буценко",
          From: "4 курс, Иркутск",
          Text: `«Я решил пройти стажировку для института в IT-<br/>
            компании и узнал об «Октагоне». Туда я подал<br/>
            заявку на продуктовое направление, работал над<br/>
            разными продуктами, смог прокачать свои<br/>
            компетенции и у меня получилось пройти<br/>
            стажировку в компании, устроиться на продакт-<br/>
            менеджера.<br/>
            Теперь я работаю в IT.<br/>
            <br/>
            Участникам «Октагона» хочу пожелать упорства и<br/>
            смелости. Не бойтесь идти на новые возможности!» `,
        },
      ],

      showRequestModal: false,
      showRegistrationModal: false,
      setTypeForModal: "",
    };
  },
  methods: {
    sendFalseToParent() {
      this.showRequestModal = false;
    },
    sendCloseRegistrationModal() {
      this.showRegistrationModal = false;
    },
    openOrgRegistration(type) {
      this.setTypeForModal = type;
      this.showRegistrationModal = false;
      this.showRequestModal = true;
    },

    getFeedbackIMG(IMGName) {
      let img = require(`./../assets/img/About/FeedbacksIMG/${IMGName}.jpg`);
      return `
      background: url(${img});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      `;
    },

    updateXValue() {
      let sections = gsap.utils.toArray(".OS-Card");

      let x = 590;
      let gap_size = 36;
      let margin = 0;

      if (window.innerWidth >= 480 && window.innerWidth <= 767) {
        margin = 15;
        x = 450;
      } else if (window.innerWidth < 480) {
        margin = 15;
        x = window.innerWidth - margin * 2;
      }

      gsap.to(sections, {
        x: `-${x * (sections.length - 1) +
          gap_size * (sections.length - 1) +
          margin * 2 * (sections.length - 1)}px`,
        ease: "none",
        scrollTrigger: {
          trigger: ".OurStudents",
          pin: ".OurStudents", //В Идеале тут должен быть родитель
          pinSpacing: true,
          scrub: 0.1,
          end: `+=${sections.length * 1000}`,
        },
      });
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    this.updateXValue();
    // window.addEventListener("resize", this.updateXValue); //Не имеет эффекта
  },
  unmounted() {},
};
</script>

<style scoped>
.Backgrounds-Collection {
  position: absolute;
  display: block;

  width: 100%;
  height: 100%;

  z-index: 1;
}
.Backgrounds-Collection > * {
  pointer-events: none;
}

.Header-BG {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 0.95;

  background-image: url("./../assets/img/vkr_assets/Group_162942.svg");
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: 100% auto;

  opacity: 0.1;

  z-index: 50;
}
.Header-Top-Left {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 30vw;
  height: auto;

  aspect-ratio: 1 / 1;

  background-image: url("./../assets/img/vkr_assets/head_left.png");
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 51;
}
.Header-Top-Right {
  position: absolute;
  display: block;

  top: 0;
  right: 0;

  width: 30vw;
  height: auto;

  aspect-ratio: 1 / 1;

  background-image: url("./../assets/img/vkr_assets/head_right.png");
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 51;
}
.Header-BG-Light {
  position: absolute;
  display: block;

  top: -264px;
  left: 0;

  width: 1078px;
  height: auto;

  aspect-ratio: 1 / 0.744;

  background-image: url("./../assets/img/vkr_assets/Rectangle 9631.svg");
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: 80%;

  z-index: 1;
}
.Header-Right-BG-Light {
  position: absolute;
  display: block;

  top: -650px;
  right: 0;

  width: 80vw;
  height: auto;

  aspect-ratio: 1 / 0.744;

  background-image: url("./../assets/img/vkr_assets/block1_right_light.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 65%;
  z-index: 1;
}
.Page-Bottom-Light {
  position: absolute;
  display: block;

  bottom: 0;
  left: 0;

  width: 80vw;
  height: auto;

  aspect-ratio: 1 / 1;

  background-image: url("./../assets/img/vkr_assets/page_bottom_light.png");
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 65%;
  z-index: 1;
}

#VRKPage > *:not(.Backgrounds-Collection),
.Page-Header > *:not(.Backgrounds-Collection),
.Page-Opportunities > *:not(.Backgrounds-Collection) {
  z-index: 2;
}

.Page-Header-Add-Info {
  position: relative;
  display: block;

  margin-top: 60px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;

  color: #d3caff4d;
}

.NewStyledButton:deep(.Text) {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.NewStyledButton {
  padding: 20px 24px;

  box-shadow: 18px 15px 50px 0px #e74ccb33, 0px 5px 20px 0px #7468fa80;
}

#VRKPage {
  position: relative;
  display: flex;

  padding-bottom: 150px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  width: 100%;
  height: fit-content;
  min-height: 100vh;

  background: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );

  overflow: hidden;
}

.FirstBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 100px;

  width: 100%;
  height: fit-content;
}

.Page-Header {
  position: relative;
  display: flex;

  margin-top: 40px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  width: fit-content;
  height: fit-content;
}
.Page-Header,
.Page-Header > *,
.Page-Opportunities,
.Page-Opportunities > * {
  margin: 0% auto;
}

.Page-Opportunities {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  width: 100%;
  height: fit-content;
}

.Page-Header-Title {
  position: relative;
  display: block;

  width: fit-content;
  max-width: 768px;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0.01em;
  text-align: center;
  color: white;
}
.Page-Opportunities-Title {
  position: relative;
  display: block;

  width: fit-content;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;

  color: white;
}

.Page-Header-Desc {
  position: relative;
  display: block;

  width: fit-content;
  max-width: 792px;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;

  color: #ffffffe5;
}
.P-H-T-Star {
  font-family: "Montserrat";
  font-weight: 200;
  color: #d650d1;
}

.P-O-Block {
  position: relative;
  display: flex;

  flex-direction: column;

  width: 1100px;
  height: fit-content;
}

.P-O-B-Line {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;

  width: 100%;
  height: fit-content;
}

.P-O-B-Line.Line2 {
  justify-content: flex-end;
  top: -50px;
}

.P-O-B-Wrapper {
  position: relative;
  display: flex;

  width: 450px;
  height: 210px;

  background-image: url("./../assets/img/vkr_assets/opportunities_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.P-O-B-Text {
  position: relative;
  display: flex;

  margin: auto;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: center;

  color: white;
}
</style>

<style scoped>
.Awards-Left-Light {
  position: absolute;
  display: block;

  top: -550px;
  left: 0;

  width: 50vw;
  height: auto;

  aspect-ratio: 1 / 1;

  background-image: url("./../assets/img/vkr_assets/awards_left_light.png");
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
.Awards-Right-Light {
  position: absolute;
  display: block;

  top: -150px;
  right: 0;

  width: 50vw;
  height: auto;

  aspect-ratio: 1 / 1;

  background-image: url("./../assets/img/vkr_assets/awards_right_light.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}

.AwardsBlock > *:not(.Backgrounds-Collection) {
  z-index: 2;
}

.AwardsBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 160px;

  width: 100%;
  height: fit-content;

  overflow: visible;
}

.AwardsBlock > *,
.AwardsBlock > * > * {
  margin: 0% auto;
}

.Special-Awards,
.Earn-Money {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  width: 1050px;
  height: fit-content;
}

.Special-Awards-Title,
.Earn-Money-Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: white;

  z-index: 2;
}

.Special-Awards-List {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: center;
  gap: 100px;
}

.S-A-L-Block {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;
}

.Awards-Icon {
  position: relative;
  display: block;

  width: 40px;
  height: 40px;

  aspect-ratio: 1 / 1;

  background-image: url("./../assets/img/vkr_assets/awards_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.Awards-Text {
  position: relative;
  display: block;

  width: 210px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;

  color: white;

  hyphens: auto;
}

.Earn-Money-Card {
  position: relative;
  display: flex;

  padding: 20px 25px 40px 25px;

  flex-direction: column;
  justify-content: flex-start;

  width: fit-content;
  height: fit-content;

  border-radius: 12px;

  background: linear-gradient(
    180deg,
    rgba(28, 26, 66, 0) 5%,
    rgba(99, 91, 255, 0.1) 50%,
    rgba(99, 91, 255, 0.5) 100%
  );
}
.E-M-C-P-Block {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  width: 250px;
  height: 250px;

  background-image: url("./../assets/img/vkr_assets/money_card_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.E-M-C-P-Block > *,
.E-M-C-Bottom-Button > * {
  margin: 0% auto;
}

.E-M-C-Places {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: center;
  gap: 100px;

  width: fit-content;
  height: fit-content;
}
.E-M-C-Bottom-Button {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 20px;

  width: 100%;
  height: fit-content;
}
.E-M-C-Desc {
  position: relative;
  display: block;

  width: 478px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: center;

  color: #ffffffe5;
}

.E-M-C-P-B-Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: center;

  color: #d3caffb2;
}
.E-M-C-P-B-Desc {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;

  color: #ffffffe5;
}
.E-M-C-P-B-Desc > .Money {
  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: center;
}

.Money-Card-Style {
  width: calc(100% - 150px);
  box-shadow: 18px 15px 50px 0px #e74ccb33, 0px 5px 20px 0px #7468fa80;
}

.E-M-C-Extra-BG {
  position: absolute;
  display: block;

  top: -70%;
  left: 0%;

  width: 100%;
  height: 800px;

  background-image: url("./../assets/img/vkr_assets/money_card_extra_bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  opacity: 0.2;

  z-index: 1;
}

.Earn-Money-Card > *:not(.E-M-C-Extra-BG) {
  z-index: 2;
}
</style>

<style scoped>
.Become-Participant {
  position: relative;
  display: flex;

  margin-top: 110px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  width: 100%;
  height: fit-content;
}
.Become-Participant-Title {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;

  color: white;
}
.Become-Participant-Blocks {
  position: relative;
  display: grid;

  margin: 0% auto;

  grid-template-columns: repeat(2, calc((100% - 60px) / 2));

  width: 1000px;
  height: fit-content;

  gap: 60px;
}
.B-P-B-Element {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;
}
.B-P-B-Element > * {
  margin: auto 0%;
}
.B-P-B-E-Img {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
}

.EImage1 {
  background-image: url("./../assets/img/vkr_assets/element_place_1.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.EImage2 {
  background-image: url("./../assets/img/vkr_assets/element_place_2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.EImage3 {
  background-image: url("./../assets/img/vkr_assets/element_place_3.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.EImage4 {
  background-image: url("./../assets/img/vkr_assets/element_place_4.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Participant-Octagon-Left-Image {
  position: absolute;
  display: block;

  top: -350px;
  left: 0;

  width: 30vw;
  height: auto;

  aspect-ratio: 0.6 / 1;

  background-image: url("./../assets/img/vkr_assets/Participant-Octagon-Left-Image.svg");
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: 100% auto;

  opacity: 0.15;

  z-index: 50;
}
.Participant-Octagon-Right-Image {
  position: absolute;
  display: block;

  top: 150px;
  right: 0;

  width: 30vw;
  height: auto;

  aspect-ratio: 0.6 / 1;

  background-image: url("./../assets/img/vkr_assets/Participant-Octagon-Right-Image.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;

  opacity: 0.15;

  z-index: 50;
}

.B-P-B-E-Texts {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;
}
.B-P-B-E-T-Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;

  color: white;
}
.B-P-B-E-T-Desc {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;

  color: white;
}
.B-P-B-E-T-Desc > .Bold {
  font-weight: 600;
}

.Become-Participant-Results {
  position: relative;
  display: block;

  margin: 0% auto;

  width: 1000px;
  height: fit-content;
}
.Become-Participant-Results-Info {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;

  color: #d3caffb2;
}

.BeParticipantButton {
  width: 1000px;
  margin: 0% auto;

  box-shadow: 18px 15px 50px 0px #e74ccb33, 0px 5px 20px 0px #7468fa80;
}
.BeParticipantButton:deep(.Text) {
  font-weight: 500;
}

.Participant-Light {
  position: absolute;
  display: block;

  top: -825px;
  left: 0;

  width: 117vw;
  height: auto;

  aspect-ratio: 1 / 0.8;

  background-image: url("./../assets/img/vkr_assets/participant_light.png");
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: 54%;
  z-index: 1;
}

.Become-Participant > *:not(.Backgrounds-Collection) {
  z-index: 2;
}
</style>

<style scoped>
.OS-Light {
  position: absolute;
  display: block;

  pointer-events: none;

  bottom: -1200px;
  right: 0;

  width: 56vw;
  height: auto;

  aspect-ratio: 0.5 / 1;

  background-image: url("../assets/img/vkr_assets/os_list_light.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 80%;

  z-index: 3;
}

.OurStudents {
  position: relative;
  display: flex;

  padding-top: 130px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  width: 100%;
  height: fit-content;
}
.OS-Title {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;

  color: white;
}

.OS-List {
  position: relative;
  display: flex;

  padding: 0% 86px;
  padding-bottom: 20px;

  justify-content: flex-start;
  flex-direction: row;
  gap: 36px;

  width: auto;
  height: fit-content;

  overflow-x: hidden;
}

.OS-List > .Card {
  position: relative;
  display: flex;

  padding: 30px;

  width: 590px;
  height: auto;

  flex-basis: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  border-radius: 10px;
  background: linear-gradient(
      119deg,
      rgba(68, 0, 255, 0.1) 25.73%,
      rgba(29, 26, 66, 0.1) 57.17%,
      rgba(68, 0, 255, 0.1) 100%
    ),
    #1d1a42;

  flex-shrink: 0;
  flex-grow: 0;
}
.OS-List > .Card > .Text {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OS-List > .Card > .Info > * {
  margin-top: auto;
  margin-bottom: auto;
}
.OS-List > .Card > .Info {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.OS-List > .Card > .Info > .IMG {
  position: relative;
  display: block;

  width: 95px;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 100%;

  flex-shrink: 0;
  flex-grow: 0;
}
.OS-List > .Card > .Info > .Participant {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.OS-List .Info > .Participant > .NameSurname {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.OS-List .Info > .Participant > .ShortPInfo {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>

<style scoped>
.ParticipantTwo {
  position: relative;
  display: flex;

  margin-top: 110px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  width: 100%;
  height: fit-content;
}
.PT-Title {
  position: relative;
  display: block;

  margin: 0% auto;

  width: 1000px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0.01em;
  text-align: left;

  color: white;
}
.PT-Desc {
  position: relative;
  display: block;

  margin: 0% auto;

  width: 1000px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;

  color: #656bff;
}
.PT-Desc > .Link {
  cursor: pointer;
  color: #656bff;
  text-decoration: underline;
}
.PT-Button {
  margin: 20px auto 0px auto;

  width: 1000px;
}

.BlueButton {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 20px 24px;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;
  text-decoration: none;

  background-color: #656bff;
  border-radius: 4px;

  box-shadow: 0px 5px 20px 0px #7468fa80;

  transition: 0.4s;
}
.BlueButton:hover {
  opacity: 0.8;
}
</style>

<style scoped>
.JoinOctagon {
  position: relative;
  display: flex;

  margin: 110px auto 0px auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 1000px;
  height: fit-content;
}
.JO-Title {
  position: relative;
  display: block;

  margin: 0% auto;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;

  color: white;
}
.JO-Desc {
  position: relative;
  display: block;

  margin: 0% auto;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;

  color: #656bff;
}
.JO-Button {
  margin-top: 52px;

  width: fit-content;
}
</style>

// Mobile Adaptation
<style scoped>
@media (max-width: 480px) {
  .BlueButton {
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    width: 100%;
  }

  .Become-Participant-Results-Info {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .S-A-L-Block {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .S-A-L-Block > * {
    margin: 0% auto;
  }
  .Awards-Text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .OS-List {
    padding: 0% 0px;
    padding-bottom: 20px;
    gap: 36px;
  }

  .OS-List > .Card > .Info > .Participant > .NameSurname,
  .OS-List > .Card > .Info > .Participant > .ShortPInfo,
  .OS-List > .Card > .Text {
    font-size: 12px;
    margin-left: 0;
  }
  .OS-List > .Card > .Info > .IMG {
    width: 40px;
  }
  .OS-List > .Card {
    padding: 15px;
    width: calc(100% - 30px);
    margin: 0% 15px;
  }

  .Page-Opportunities {
    gap: 0;
  }
  .Page-Opportunities-Title,
  .Special-Awards-Title,
  .Earn-Money-Title,
  .Become-Participant-Title,
  .OS-Title,
  .PT-Title,
  .JO-Title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }
  .P-O-B-Wrapper {
    width: 100%;
  }
  .P-O-B-Text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;

    width: 100%;
  }

  .NewStyledButton {
    padding: 12px 0px;
    width: 100%;
  }
  .NewStyledButton:deep(.Text) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  #VRKPage {
    padding-bottom: 50px;
  }

  .PT-Desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #d3caffb2;
  }

  .B-P-B-Element > * {
    margin: 0% 0% auto 0%;
  }

  .Become-Participant-Blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .B-P-B-E-Img {
    width: 60px;
  }

  .Money-Card-Style {
    width: 100%;
  }

  .E-M-C-Desc {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  .Earn-Money-Card {
    background: transparent;
  }

  .E-M-C-Extra-BG {
    display: none;
  }

  .Page-Header-Title {
    font-size: 32px;
    font-weight: 600;
    line-height: 28px;
  }

  .B-P-B-E-T-Title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
  }
  .B-P-B-E-T-Desc {
    position: relative;
    display: block;

    width: fit-content;
    height: fit-content;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;

    color: white;
  }

  .Page-Header {
    margin-top: 50px;
  }

  .Special-Awards-List {
    flex-direction: column;
  }

  .Awards-Text {
    width: 100%;
  }

  .Become-Participant-Results,
  .Become-Participant-Blocks,
  .Special-Awards,
  .Earn-Money,
  .BeParticipantButton,
  .PT-Title,
  .PT-Desc,
  .PT-Button,
  .JoinOctagon,
  .Page-Header-Title {
    width: 100%;
  }

  .P-O-Block {
    width: 100%;
    gap: 0px;
  }
  .P-O-B-Line {
    justify-content: center;
    flex-direction: column;
    gap: 0;
  }
  .P-O-B-Line.Line2 {
    justify-content: center;
    top: 0px;
  }

  .Special-Awards,
  .Earn-Money {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
  }

  .Special-Awards-List {
    gap: 40px;
  }

  .E-M-C-Places {
    gap: 0px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .E-M-C-P-Block {
    gap: 20px;
    width: 200px;
    height: 200px;
    margin: 0% auto;
  }
  .E-M-C-P-B-Title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
  }
  .E-M-C-P-B-Desc {
    font-size: 32px;
    line-height: 100%;
  }
  .E-M-C-P-B-Desc > .Money {
    font-size: 22px;
    line-height: normal;
  }

  .Become-Participant-Blocks {
    grid-template-columns: repeat(2, calc((100% - 40px) / 2));
    gap: 40px;
  }

  .Become-Participant-Blocks,
  .Become-Participant-Results,
  .ParticipantTwo,
  .Become-Participant > a,
  .JoinOctagon,
  .Page-Header-Title,
  .Special-Awards,
  .Earn-Money-Card,
  .Page-Header-Desc,
  .Page-Header > a,
  .P-O-B-Wrapper {
    padding: 0% 16px;
  }
  .Page-Header > a {
    margin: 0%;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .OS-List {
    padding: 0% 0px;
    padding-bottom: 20px;
    gap: 36px;
  }

  .OS-List > .Card > .Info > .Participant > .NameSurname,
  .OS-List > .Card > .Info > .Participant > .ShortPInfo,
  .OS-List > .Card > .Text {
    font-size: 14px;
    margin-left: 0;
  }
  .OS-List > .Card > .Info > .IMG {
    width: 60px;
  }
  .OS-List > .Card {
    width: 450px;
    margin: 0% 15px;
  }

  #VRKPage {
    padding-bottom: 100px;
  }

  .PT-Title {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .PT-Desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #d3caffb2;
  }

  .B-P-B-Element > * {
    margin: 0% 0% auto 0%;
  }

  .Become-Participant-Blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .B-P-B-E-Img {
    width: 60px;
  }

  .Money-Card-Style {
    width: 100%;
  }

  .E-M-C-Desc {
    width: 100%;
  }

  .Earn-Money-Card {
    background: transparent;
  }

  .E-M-C-Extra-BG {
    display: none;
  }

  .Page-Header-Title {
    font-size: 32px;
    font-weight: 600;
    line-height: 28px;
  }

  .B-P-B-E-T-Title {
    font-size: 22px;
    line-height: normal;
  }
  .B-P-B-E-T-Desc {
    position: relative;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    color: white;
  }

  .Page-Header {
    margin-top: 100px;
  }

  .Special-Awards-List {
    flex-direction: column;
  }

  .Awards-Text {
    width: 100%;
  }

  .Become-Participant-Results,
  .Become-Participant-Blocks,
  .Special-Awards,
  .Earn-Money,
  .BeParticipantButton,
  .PT-Title,
  .PT-Desc,
  .PT-Button,
  .JoinOctagon,
  .Page-Header-Title {
    width: 100%;
  }

  .P-O-Block {
    width: 100%;
    gap: 40px;
  }
  .P-O-B-Line {
    justify-content: center;
    flex-direction: column;
  }
  .P-O-B-Line.Line2 {
    justify-content: center;
    top: 0px;
  }
  .P-O-B-Wrapper {
    margin: 0% auto;
  }

  .Special-Awards,
  .Earn-Money {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
  }

  .Special-Awards-List {
    gap: 40px;
  }

  .E-M-C-Places {
    gap: 0px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .E-M-C-P-Block {
    gap: 20px;
    width: 200px;
    height: 200px;
    margin: 0% auto;
  }
  .E-M-C-P-B-Title {
    font-size: 24px;
  }
  .E-M-C-P-B-Desc {
    font-size: 32px;
    line-height: 100%;
  }
  .E-M-C-P-B-Desc > .Money {
    font-size: 22px;
    line-height: normal;
  }

  .Become-Participant-Blocks {
    grid-template-columns: repeat(2, calc((100% - 40px) / 2));
    gap: 40px;
  }

  .Become-Participant-Blocks,
  .Become-Participant-Results,
  .ParticipantTwo,
  .Become-Participant > a,
  .JoinOctagon,
  .Page-Header-Title,
  .Special-Awards,
  .Earn-Money-Card,
  .Page-Header-Desc {
    padding: 0% 40px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .P-O-B-Wrapper {
    width: 350px;
  }
  .P-O-B-Text {
    font-size: 20px;
  }

  .B-P-B-E-T-Title {
    font-size: 22px;
    line-height: normal;
  }
  .B-P-B-E-T-Desc {
    position: relative;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    color: white;
  }

  .Special-Awards-List {
    flex-direction: column;
  }

  .Awards-Text {
    width: 410px;
  }

  .Become-Participant-Results,
  .Become-Participant-Blocks,
  .Special-Awards,
  .Earn-Money,
  .BeParticipantButton,
  .PT-Title,
  .PT-Desc,
  .PT-Button,
  .JoinOctagon {
    width: 100%;
  }

  .P-O-Block {
    width: 100%;
    gap: 50px;
  }
  .P-O-B-Line {
    justify-content: flex-start;
    width: fit-content;
    margin: 0% auto;
  }
  .P-O-B-Line.Line2 {
    justify-content: flex-start;
    top: 0px;
  }

  .Special-Awards,
  .Earn-Money {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
  }

  .Special-Awards-List {
    gap: 40px;
  }

  .E-M-C-Places {
    gap: 0px;
  }

  .E-M-C-P-Block {
    gap: 20px;
    width: 200px;
    height: 200px;
  }
  .E-M-C-P-B-Title {
    font-size: 24px;
  }
  .E-M-C-P-B-Desc {
    font-size: 32px;
    line-height: 100%;
  }
  .E-M-C-P-B-Desc > .Money {
    font-size: 22px;
    line-height: normal;
  }

  .Become-Participant-Blocks {
    grid-template-columns: repeat(2, calc((100% - 40px) / 2));
    gap: 40px;
  }

  .Become-Participant-Blocks,
  .Become-Participant-Results,
  .ParticipantTwo,
  .Become-Participant > a,
  .JoinOctagon,
  .Page-Header-Desc {
    padding: 0% 40px;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .Become-Participant-Results,
  .Become-Participant-Blocks,
  .Special-Awards,
  .Earn-Money,
  .BeParticipantButton,
  .PT-Title,
  .PT-Desc,
  .PT-Button,
  .JoinOctagon {
    width: 100%;
  }

  .P-O-Block {
    width: 100%;
    gap: 50px;
  }
  .P-O-B-Line {
    justify-content: center;
  }
  .P-O-B-Line.Line2 {
    justify-content: center;
    top: 0px;
  }

  .Special-Awards,
  .Earn-Money {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
  }

  .Special-Awards-List {
    gap: 40px;
  }

  .E-M-C-Places {
    gap: 50px;
  }

  .Become-Participant-Blocks {
    grid-template-columns: repeat(2, calc((100% - 40px) / 2));
    gap: 40px;
  }

  .Become-Participant-Blocks,
  .Become-Participant-Results,
  .ParticipantTwo,
  .Become-Participant > a,
  .JoinOctagon {
    padding: 0% 40px;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
}
@media (min-width: 1600px) {
}
</style>
