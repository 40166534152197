<template>
  <div id="LandingWrapper">
    <div class="Landing">
      <div id="ImgBlur1" class="LandingBackgroundBlur" />
      <div id="ImgBlur2" class="LandingBackgroundBlur" />
      <div id="ImgBlur3" class="LandingBackgroundBlur" />
      <div id="ImgBlur4" class="LandingBackgroundBlur" />

      <LandingNavbar
        :SendRequestBusiness="
          () => {
            showRegistrationModal = true;
          }
        "
      />

      <div class="ContentBlocks">
        <div class="ContentBlock FlexBlock Flex-H">
          <div class="ContentAutoBlock FlexBlock Flex-V">
            <p class="ContentHeader">
              Разберём <span style="font-weight: 700;">вашу задачу</span><br />
              по разработке и <br />
              автоматизации легко!
            </p>
            <p class="ContentHeaderList">
              Кузница IT-кадров, в которой молодые<br />
              специалисты приобретают необходимые<br />
              компетенции, решая реальные задачи от<br />
              бизнеса.
            </p>

            <ButtonGradientInside
              v-if="!user"
              ButtonText="Оставить заявку"
              ButtonLink="#"
              @click.native="showRegistrationModal = true"
              :NeedIcon="true"
              :NotUseRouter="true"
            />
            <ButtonGradientInside
              v-if="user"
              :ButtonText="`Мой профиль`"
              :ButtonLink="
                user &&
                user.hasOwnProperty(`organization`) &&
                user.organization.length > 0
                  ? `/organization/profile`
                  : `/beta/profile`
              "
              :NeedIcon="true"
            />

            <router-link
              class="ButtonGetProjectList"
              to="/project/projectsList"
            >
              Посмотреть список существующих проектов
            </router-link>
            <div class="BackgroundImages" id="ImgLogoRectangle" />

            <div class="BackgroundImages" id="ImgLine" />

            <div class="BackgroundImages" id="ImgCircleDotted" />
            <div class="BackgroundImages" id="ImgCircle" />

            <div class="BackgroundImages" id="ImgTriangleDotted" />
            <div class="BackgroundImages" id="ImgTriangle" />

            <div class="BackgroundImages" id="ImgPlus" />

            <div class="BackgroundImages" id="ImgLogo" />
          </div>
        </div>

        <div class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Вам интересен Октагон, если Вы хотите:</p>
          <div class="Benefits">
            <Benefit
              v-for="item in WhatYouGet"
              :icon="`${item.icon}`"
              :title="item.title"
              :text="item.text"
              :needVerticalAlignment="true"
              :UseThreeBlockPerLine="true"
              :key="item"
            />
          </div>
        </div>

        <div class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Как это работает?</p>
          <div class="Benefits">
            <SimpleBenefit
              v-for="item in simpleBenefits"
              :icon="`${item.icon}`"
              :text="item.text"
              :key="item"
            />
          </div>
        </div>

        <div id="SuccessfulCases" class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Успешные кейсы</p>
          <div class="CasesListContainer">
            <div class="CasesListWrapper">
              <div class="CasesList">
                <div
                  class="CaseBlock"
                  v-for="i in SuccessfulCases"
                  :key="i.title"
                >
                  <span class="CBTitle">{{ i.title }}</span>

                  <div class="CBWrap" v-show="i.taskText != ''">
                    <span class="CBWHead">Задача:</span>
                    <span class="CBWBody" v-html="i.taskText"></span>
                  </div>

                  <div class="CBWrap" v-show="i.developerText != ''">
                    <span class="CBWHead">Исполнитель:</span>
                    <span class="CBWBody" v-html="i.developerText"></span>
                  </div>

                  <div class="CBWrap" v-show="i.taskSolving != ''">
                    <span class="CBWHead">Решение задачи:</span>
                    <span class="CBWBody" v-html="i.taskSolving"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="PartnersBlock" class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Работаем при активной поддержке</p>
          <div class="ImagesBlock">
            <div class="PartnerImagePhoto PI_1C"></div>
            <div class="PartnerImagePhoto PI_MOIO"></div>
            <div class="PartnerImagePhoto PI_IRNITU"></div>
            <div class="PartnerImagePhoto PI_MICCIFRI"></div>
            <div class="PartnerImagePhoto PI_OBLASTMOLODIH"></div>
          </div>

          <div class="FullWidthBlock"></div>
        </div>

        <div id="OctagonToday" class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Октагон сегодня</p>
          <div class="OTBlocks">
            <div class="OTBlock">
              <div class="OTBHead"></div>
              <span class="OTBText"
                >«Сильные идеи для нового времени»: <br />ТОП-региональные идеи
                2022»</span
              >
            </div>
            <div class="OTBlock">
              <div class="OTBHead"></div>
              <span class="OTBText"
                >Победитель конкурса 1С <br />«Новое поколение 1С»</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ContentBlock FlexBlock Flex-H ContentFooter">
      <ButtonGradientInside
        v-if="user"
        :ButtonText="`Мой профиль`"
        :ButtonLink="
          user &&
          user.hasOwnProperty(`organization`) &&
          user.organization.length > 0
            ? `/organization/profile`
            : `/beta/profile`
        "
        :NeedIcon="true"
      />
      <ButtonGradientInside
        v-if="!user"
        ButtonText="Оставить заявку"
        ButtonLink="#"
        :NeedIcon="true"
        @click="showRegistrationModal = true"
      />
      <ButtonGradient
        ButtonText="Список проектов"
        ButtonLink="/project/projectsList"
        :NeedIcon="true"
      />
    </div>

    <el-drawer
      v-model="openBurgerMenu"
      direction="ttb"
      modal-class="BurgerMenuButtons"
      :with-header="false"
      size="auto"
    >
      <div class="BurgerMenuButtons">
        <div class="OctagonLogo"></div>

        <router-link class="ButtonLink" to="/">
          Студентам
        </router-link>
        <router-link class="ButtonLink ButtonLinkSelected" to="/business">
          Партнёрам
        </router-link>
        <!-- <router-link class="ButtonLink " to="/gos">
          Государственному сектору
        </router-link> -->
        <ButtonGradientInside
          v-if="user"
          :ButtonText="
            user &&
            user.hasOwnProperty(`organization`) &&
            user.organization.length > 0
              ? user.organization[0].organization_name
              : `Мой профиль`
          "
          :ButtonLink="
            user &&
            user.hasOwnProperty(`organization`) &&
            user.organization.length > 0
              ? `/organization/profile`
              : `/beta/profile`
          "
          :NeedIcon="true"
        />
        <ButtonGradientInside
          v-if="!user"
          ButtonText="Оставить заявку"
          ButtonLink="#"
          :NotUseRouter="true"
          :NeedIcon="true"
          @click="
            showRequestModal = true;
            openBurgerMenu = false;
          "
        />
        <ButtonGradient
          ButtonText="Список проектов"
          ButtonLink="/project/projectsList"
          :NeedIcon="true"
        />
      </div>
    </el-drawer>
    <SendRequest
      @sendFalseToParent="sendFalseToParent"
      :key="`${sendFalseToParent}${setTypeForModal}`"
      :setType="setTypeForModal"
      :showModal="showRequestModal"
    />

    <RegistrationModal
      @sendCloseRegistrationModal="sendCloseRegistrationModal"
      :openOrgRegistration="openOrgRegistration"
      :showModal="showRegistrationModal"
    />
  </div>
</template>

<script>
import SendRequest from "@/components/Landing/SendRequest";
import RegistrationModal from "@/components/Landing/RegistrationModal";

import Benefit from "@/components/Landing/BenefitsBlock";
import SimpleBenefit from "@/components/Landing/SimpleBenefit";

import ButtonGradientInside from "@/components/Landing/ButtonInsideGradient";
import ButtonGradient from "@/components/Landing/ButtonGradient";

import LandingNavbar from "@/components/Landing/Navbar";

import { mapGetters, mapMutations } from "vuex";

import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/400.css";

import "@fontsource/montserrat/400.css";

export default {
  name: "LandingEntrepreneurs",
  components: {
    SimpleBenefit,
    Benefit,
    ButtonGradientInside,
    ButtonGradient,
    SendRequest,
    LandingNavbar,
    RegistrationModal,
  },
  data() {
    return {
      showRequestModal: false,
      showRegistrationModal: false,
      setTypeForModal: "",

      openBurgerMenu: false,

      OrgFormValidation: {
        isValidPhoneNumber: false,
        isValidEmail: false,
      },
      WhatYouGet: [
        {
          icon: 1,
          title: "Наращивать кадровый IT-потенциал",
          text:
            "Вам не нужно перебирать резюме 1000 кандидатов. В нашем проекте уже есть готовые IT-специалисты, которые работали над реальными задачами.  ",
        },
        {
          icon: 2,
          title: "Решать производственные <br> задачи",
          text:
            "Сможете решать нестандартные задачи и экономить на проверке гипотез.",
        },
        {
          icon: 3,
          title: "Заняться поддержкой <br> IT-стартапов",
          text:
            "Вы получаете комплекс услуг и ресурсов, позволяющих начать работу над проектом с меньшими затратами.",
        },
        {
          icon: 4,
          title: "Поддерживать <br> HR-бренд компании",
          text:
            "Улучшить общую репутацию компании, привлечь больше клиентов и инвесторов.",
        },
      ],

      simpleBenefits: [
        {
          icon: 1,
          text:
            "Сформулируйте задачу, которую необходимо решить, постарайтесь подробно её описать.",
        },
        {
          icon: 2,
          text:
            "Специалист свяжется с Вами вскоре после анализа ваших бизнес-требований.",
        },
        {
          icon: 3,
          text:
            "Ваша задача попадет в общую базу  и станет доступной для выполнения участниками Октагона.",
        },
        {
          icon: 4,
          text:
            "После выполнения задачи менеджеры Октагона передадут Вам результаты своей работы.",
        },
      ],

      SuccessfulCases: [
        {
          title: "Приложение по доставке",
          developerText: "Михаил Гусаков, Екатерина Косянская, Илья Зайцев",
          taskText: "Реализовать приложение по доставке",
          taskSolving: `
          1) Реализован модуль на python для планирования доставок;<br/>
          2) Настроена интеграция с 1С:УТ, внедрено в конфигурацию клиента.<br/>
          3) Реализован удобный модуль планирования доставки в 1С:УТ<br/>
          4) Интегрированы карты (для разделения на районы и геокодирования)<br/>
          5) Реализовано мобильное приложение на платформе 1С.<br/>
          Благодаря разработке мобильного приложения уменьшилось планируемое окно доставки для клиентов и повысилось качество обслуживания.
`,
        },
        {
          title: "Сокращение адреса",
          developerText: "студент Дмитрий Гусаков",
          taskText:
            "В мобильном приложении для доставщика есть список заданий-адресов, которые нужно посетить. Адреса в приложении выводятся полные и занимают много места на экране телефона.",
          taskSolving: `Для того, чтобы сделать адрес более компактным, было реализовано правило при помощи регулярных выражений.<br/>
        <b>Было</b>: 664018, Иркутская обл, Иркутск г, Багратиона ул, дом № 56/4, кв.38 <br/>
        <b>Стало</b>: ул. Багратиона, 56/4
`,
        },
        {
          title: "Поиск номера телефона",
          developerText: "студент Виктория Бубнова",
          taskText:
            "Реализовать возможность совершать звонок по номеру из мобильного приложения.",
          taskSolving:
            "В базе данных клиента номера хранятся  беспорядочно, (с дополнительными символами). Для того, чтобы из этих строк получать реальный номер и отображать его в приложении, был произведён разбор строки поиском определённых символов. Таким образом, было разработано правило, по которому открылась возможность искать информацию в строке.",
        },
      ],

      showRegistrationContinue: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  methods: {
    sendFalseToParent() {
      this.showRequestModal = false;
    },
    sendCloseRegistrationModal() {
      this.showRegistrationModal = false;
    },
    openOrgRegistration(type) {
      this.setTypeForModal = type;
      this.showRegistrationModal = false;
      this.showRequestModal = true;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

#LandingWrapper {
  position: relative;

  left: 0%;
  top: 0%;

  margin: 0%;
  padding: 0%;

  width: 100%;
  height: auto;

  background: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );

  overflow-x: hidden;
}

#LandingWrapper > .Landing {
  position: relative;

  width: 100%;
  height: auto;
}

.Navbar {
  position: relative;

  width: auto;
  height: auto;

  padding: 38px 117px 106px 117px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Navbar > .NavbarBlock {
  position: relative;

  width: auto;

  display: flex;
  flex-direction: row;
  align-content: center;
}

.Navbar > .NavbarBlock:nth-child(1) > * {
  margin: auto 8.75px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(2),
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(3) {
  margin: auto 28px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(4) {
  margin: auto 53px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(5) {
  margin: auto 0% auto 0px;
}

.ContentBlock > .ContentAutoBlock {
  position: relative;

  width: auto;
  height: auto;
}
.ContentBlocks > .ContentBlock:nth-child(1) {
  padding: 0% 120px 0% 115px;
  margin-bottom: 165px;
}
.ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
  width: 100%;
  height: 40vw;
}

.ContentBlocks
  > .ContentBlock:nth-child(1)
  > .ContentAutoBlock
  > *:not(.BackgroundImages) {
  z-index: 20;
}

.ContentBlock > .ContentTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;

  margin-bottom: 59px;
  font-family: "Montserrat";

  /* or 117% */
  text-align: center;

  color: #ffffff;
}

.Benefits {
  position: relative;

  width: 100%;
  height: auto;

  padding: 0% 135px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ContentBlocks
  > .ContentBlock:nth-child(1)
  > .ContentAutoBlock
  > .ButtonFooterGradientInside {
  margin-top: 29px;
  margin: auto auto auto 0% !important;
}

#MobileNavbar {
  position: relative;

  width: 100%;
  height: 53px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6vw;
}

#ImgBlur1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 1432px;
  background-image: url("./../assets/img/Landing/Figure1.svg");
  filter: blur(150px);
}
#ImgBlur2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 100% 10%;
  background-repeat: no-repeat;
  background-size: 1618px;
  background-image: url("./../assets/img/Landing/Figure2.svg");
  filter: blur(150px);
}
#ImgBlur3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 850px;
  background-image: url("./../assets/img/Landing/Figure3.svg");
  filter: blur(150px);
}
#ImgBlur4 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 850px;
  background-image: url("./../assets/img/Landing/Figure4.svg");
  filter: blur(150px);
}

#ImgLogo {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(-1, 1);

  background-position: calc(50% + (32vw) / 1.8) calc(50%);
  background-repeat: no-repeat;
  background-size: 32vw;
  background-image: url("./../assets/img/Landing/HeaderFigures/Logo2.svg");
}
#ImgLogoRectangle {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + (32vw) / 1.8) calc(50%);
  background-repeat: no-repeat;
  background-size: 32vw;
  background-image: url("./../assets/img/Landing/HeaderFigures/Rectangle.svg");
}

#ImgLine {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: 50% calc(50% - 14vw);
  background-repeat: no-repeat;
  background-size: 5vw auto;
  background-image: url("./../assets/img/Landing/HeaderFigures/Line.svg");
}

#ImgCircle {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + 32vw) calc(50% - 18vw);
  background-size: 2.5vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/Circle.svg");
}
#ImgCircleDotted {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + 32.6vw) calc(50% - 16.8vw);
  background-size: 2.5vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/CircleDotted.svg");
}

#ImgTriangle {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% - 2vw) calc(50% + 14.5vw);
  background-size: 2.6vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/Triangle.svg");
}
#ImgTriangleDotted {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% - 1.5vw) calc(50% + 15.5vw);
  background-size: 2.6vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/TriangleDotted.svg");
}

#ImgPlus {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + 34vw) calc(50% + 12vw);
  background-size: 2vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/Plus.svg");
}

.FlexBlock {
  display: flex;
}
.Flex-H {
  flex-direction: row;
}
.Flex-V {
  flex-direction: column;
}

.OctagonTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 140%;

  color: #ffffff;
}
.OctagonLogo {
  position: relative;

  width: 12vw;
  height: 4vw;

  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: auto 115%;
  background-image: url("./../assets/img/Landing/NewOctagonLogo.svg");

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.Navbar > .NavbarBlock > .ButtonLinkSelected,
.BurgerMenuButtons .ButtonLinkSelected {
  color: rgba(83, 185, 234, 0.7);
}
.ButtonLink {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.7);

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.ButtonFooter {
  text-decoration: none;

  width: 290px;
  height: 58px;

  margin: 0% 34px;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #ffffff;
}
.ButtonFooterGradient {
  position: relative;

  width: 290px;
  height: 58px;

  padding: 16px;
  text-align: center;

  color: #ffffff;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}
.ButtonFooterGradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px; /* control the border thickness */
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ButtonFooterGradientInside {
  position: relative;

  width: 290px;
  height: 58px;

  padding: 16px;
  text-align: center;

  color: #ffffff;

  border: 0px solid;
  backdrop-filter: blur(1px);
  border-radius: 4px;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

.ButtonGradient {
  position: relative;

  width: 210px;
  height: 40px;

  padding: 9px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 22px;

  text-align: center;

  color: #ffffff;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}
.ButtonGradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 1px; /* control the border thickness */
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ContentHeader {
  font-family: "Montserrat";
  position: relative;
  margin-top: 8vw;
  margin-bottom: 23px;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  line-height: 79px;

  color: #ffffff;
}
.ContentHeaderList {
  margin-top: 8px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 30px;

  letter-spacing: -0.656526px;

  color: rgba(255, 255, 255, 0.7);
}

.centerButtonText {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.centerButtonText > p {
  position: relative;
  margin: auto 0px auto auto;
}
.centerButtonText > img {
  position: relative;

  width: 12px;
  height: 12px;

  margin: auto auto auto 14px;
}

#MobileNavbar {
  position: relative;

  width: auto;
  height: 100%;

  margin: auto;

  display: none;
  z-index: 1001;
}
#MobileNavbar > i {
  position: relative;
  margin: auto auto auto 10px;
}

.AutoMarginWidth > *:nth-last-child(1) {
  margin-left: 2vw;
  margin-right: auto;
}
.AutoMarginWidth > *:nth-child(1) {
  margin-left: auto;
  margin-right: 2vw;
}

.ContentBlockFooter {
  margin-top: 6vw;
  margin-bottom: 6vw;
}

.ButtonGetProjectList {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;

  color: #ffffff;
}

.FormData {
  position: relative;

  width: 500px;
  height: auto;

  margin: 0% auto;
}
.FormData .el-form-item {
  color: #fff;
}

.ContentFooter {
  position: relative;

  display: flex;
  justify-content: center;

  padding: 60px 0%;
  background: rgba(30, 30, 32, 0.5);
}
</style>

<style scoped>
@media (max-width: 479px) {
  .Landing #SuccessfulCases > .CasesListContainer {
    height: 750px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock {
    padding: 15px;
    width: 320px;
    gap: 15px;
  }
  .Landing
    #SuccessfulCases
    > .CasesListContainer
    > .CasesListWrapper
    > .CasesList {
    padding: 0% 15px 8px 15px;
    gap: 30px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBTitle {
    font-size: 17px;
    line-height: normal;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap {
    gap: 5px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWHead {
    font-size: 14px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWBody {
    font-size: 14px;
  }

  .Landing #OctagonToday > .OTBlocks {
    flex-direction: column;

    padding: 0% 15px;
  }
  .Landing #OctagonToday > .OTBlocks > * {
    margin-left: auto;
    margin-right: auto;
  }
  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBHead {
    width: 38px;
    height: 36px;
  }
  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBText {
    font-size: 16px;
    text-align: justify;

    width: 205px;
  }

  .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > .ButtonGetProjectList {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 3rem 10% 0px 10%;
  }

  .LandingBackgroundBlur {
    display: none;
  }

  .Navbar {
    position: fixed;

    width: 100%;

    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    background-color: rgb(20, 20, 20);

    z-index: 100;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:not(#MobileNavbar) {
    display: none;
  }
  .Navbar > .NavbarBlock:nth-child(2) > #MobileNavbar {
    display: flex;
    color: #e0e0e0;
  }

  .OctagonLogo {
    width: 80vw;
    height: 10vw;
  }

  .ContentBlocks {
    padding-top: 100px;
  }

  .Landing > .ContentBlocks {
    display: flex;
    flex-direction: column;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 0% 4vw;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
    position: relative;

    width: 100%;
    height: auto;

    display: flex;

    flex-direction: column;
    justify-content: center;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeaderList) {
    position: relative;
    width: auto;

    padding-top: 100vw;

    text-align: center;

    font-size: 8vw;
    line-height: 10vw;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeader) {
    position: relative;
    width: auto;
    font-size: 4vw;
    line-height: 6vw;
    text-align: center;
  }
  .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > .ButtonFooterGradientInside {
    margin: 8vw auto auto auto !important;
  }

  .Landing .ImagesBlock {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }
  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 100px;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
  }
  .ContentTitle {
    position: relative;
    width: auto;
    font-size: 8vw !important;
    line-height: 10vw !important;

    justify-content: center;
  }

  .Landing .ButtonFooterGradientInside,
  .Landing .ButtonFooterGradient {
    width: auto;
    height: auto;

    margin: 4vw 0%;

    font-size: 4vw;
    line-height: 6vw;

    padding: 4vw 6vw;
  }

  .Landing .Benefits {
    padding: 0%;
  }

  #ImgLogo {
    background-position: calc(50%) calc(12vw);
    background-size: 76vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50%) calc(10vw);
    background-size: 76vw;
  }

  #ImgLine {
    background-position: calc(50% - 40vw) calc(14vw);
    background-size: 10vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 37vw) calc(6vw);
    background-size: 5vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 38.5vw) calc(8vw);
    background-size: 5vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 36vw) calc(82vw);
    background-size: 5vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 35vw) calc(84vw);
    background-size: 5vw;
  }

  #ImgPlus {
    background-position: calc(50% + 34vw) calc(80vw);
    background-size: 5vw;
  }

  .AutoMarginWidth {
    flex-direction: column;
  }
  .AutoMarginWidth > *:nth-last-child(1) {
    margin-left: 10 !important;
    margin-right: auto !important;
  }
  .AutoMarginWidth > *:nth-child(1) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .AutoMarginWidth > * {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .ContentBlockFooter {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .FormData {
    width: 96%;
  }

  #LandingWrapper > .ContentFooter {
    flex-direction: column;
  }
  #LandingWrapper > .ContentFooter > * {
    margin: 2vw 35px !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .Landing #SuccessfulCases > .CasesListContainer {
    height: 750px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock {
    padding: 20px;
    width: 450px;
    gap: 30px;
  }
  .Landing
    #SuccessfulCases
    > .CasesListContainer
    > .CasesListWrapper
    > .CasesList {
    padding: 0% 15px 8px 15px;
    gap: 30px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBTitle {
    font-size: 20px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap {
    gap: 10px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWHead {
    font-size: 16px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWBody {
    font-size: 16px;
  }

  .Landing #OctagonToday > .OTBlocks {
    flex-direction: column;
  }
  .Landing #OctagonToday > .OTBlocks > * {
    margin-left: auto;
    margin-right: auto;
  }

  .LandingBackgroundBlur {
    display: block;
  }

  .Navbar {
    position: fixed;

    width: 100%;

    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    background-color: rgb(20, 20, 20);

    z-index: 100;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:not(#MobileNavbar) {
    display: none;
  }
  .Navbar > .NavbarBlock:nth-child(2) > #MobileNavbar {
    display: flex;
    color: #e0e0e0;
  }

  .OctagonLogo {
    width: 80vw;
    height: 8vw;
  }

  .ContentBlocks {
    padding-top: 100px;
  }

  .Landing > .ContentBlocks {
    display: flex;
    flex-direction: column;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 0% 6vw;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
    position: relative;

    width: 100%;
    height: auto;

    display: flex;

    flex-direction: column;
    justify-content: center;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeaderList) {
    position: relative;
    width: auto;

    padding-top: 100vw;
    margin-bottom: 8vw;

    text-align: center;

    font-size: 7vw;
    line-height: 9vw;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeader) {
    position: relative;
    width: auto;
    font-size: 4.5vw;
    line-height: 7vw;
    text-align: center;
  }
  .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > .ButtonFooterGradientInside {
    margin: 8vw auto auto auto !important;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) {
    margin-bottom: 15vw;
  }

  .Landing .ImagesBlock {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }
  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 100px;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
  }
  .ContentTitle {
    position: relative;
    width: auto;
    font-size: 7vw !important;
    line-height: 9vw !important;

    justify-content: center;
  }

  .Landing .ButtonFooterGradientInside,
  .Landing .ButtonFooterGradient {
    width: auto;
    height: auto;

    margin: 4vw 0%;

    font-size: 4vw;
    line-height: 6vw;

    padding: 4vw 6vw;
  }

  .Landing .Benefits {
    padding: 0%;
  }

  .ButtonGetProjectList {
    font-size: 2.6vw;
    line-height: 3vw;

    margin: 5vw auto;
  }

  #ImgLogo {
    background-position: calc(50%) calc(12vw);
    background-size: 76vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50%) calc(10vw);
    background-size: 76vw;
  }

  #ImgLine {
    background-position: calc(50% - 40vw) calc(14vw);
    background-size: 10vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 37vw) calc(6vw);
    background-size: 5vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 38.5vw) calc(8vw);
    background-size: 5vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 36vw) calc(82vw);
    background-size: 5vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 35vw) calc(84vw);
    background-size: 5vw;
  }

  #ImgPlus {
    background-position: calc(50% + 34vw) calc(80vw);
    background-size: 5vw;
  }

  .AutoMarginWidth {
    flex-direction: column;
  }
  .AutoMarginWidth > *:nth-last-child(1) {
    margin-left: 10 !important;
    margin-right: auto !important;
  }
  .AutoMarginWidth > *:nth-child(1) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .AutoMarginWidth > * {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .ContentBlockFooter {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .FormData {
    width: 80%;
  }

  #LandingWrapper > .ContentFooter {
    flex-direction: column;
  }
  #LandingWrapper > .ContentFooter > * {
    margin: 2vw 35px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Landing #SuccessfulCases > .CasesListContainer {
    height: 750px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock {
    padding: 30px;
    width: 520px;
    gap: 30px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBTitle {
    font-size: 24px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap {
    gap: 10px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWHead {
    font-size: 17px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWBody {
    font-size: 17px;
  }

  .Landing #OctagonToday > .OTBlocks {
    flex-direction: column;
  }
  .Landing #OctagonToday > .OTBlocks > * {
    margin-left: auto;
    margin-right: auto;
  }

  .OctagonLogo {
    width: 20vw;
    height: 3vw;
  }

  .Navbar > .NavbarBlock:nth-child(2) > a {
    font-size: 1.4vw;
    font-weight: 1.4vw;

    margin: auto 1vw !important;
  }

  .ContentBlocks {
    padding-top: 4vw;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 2vw 6vw;
  }

  .Navbar {
    padding: 6vw;
  }
  .Navbar > .NavbarBlock:nth-child(2) {
    height: auto;
    margin: auto 0%;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeaderList) {
    font-size: 4vw;
    line-height: 4vw;
    margin-top: 4vw;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeader) {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  .ButtonGetProjectList {
    font-size: 1.6vw;
    line-height: 2vw;

    margin-top: 3vw;
  }

  #ImgLogo {
    background-position: calc(50% - (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50% + (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }

  #ImgLine {
    background-position: 50% calc(50% - 18vw);
    background-size: 5vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 40vw) calc(50% - 18vw);
    background-size: 3vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 40.6vw) calc(50% - 16.8vw);
    background-size: 3vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 3vw) calc(50% + 14.5vw);
    background-size: 2.6vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 2.5vw) calc(50% + 15.5vw);
    background-size: 2.6vw;
  }

  #ImgPlus {
    background-position: calc(50% + 42vw) calc(50% + 18vw);
    background-size: 2.4vw;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) {
    margin-bottom: 2vw;
  }
  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 100px;
    background-size: contain;
  }
  .ContentTitle {
    position: relative;
    width: auto;
    font-size: 4vw !important;
    line-height: 4vw !important;

    justify-content: center;
  }

  .Benefits {
    padding: 0% 0%;
    margin: auto;
    gap: 1vw;
  }

  .AutoMarginWidth > *:nth-last-child(1) {
    margin-left: 35px !important;
    margin-right: auto !important;
  }
  .AutoMarginWidth > *:nth-child(1) {
    margin-left: auto !important;
    margin-right: 35px !important;
  }
  .ContentBlockFooter {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  #LandingWrapper > .ContentFooter > * {
    margin: 0% 35px !important;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  .Landing #SuccessfulCases > .CasesListContainer {
    height: 750px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock {
    padding: 30px;
    width: 590px;
    gap: 30px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBTitle {
    font-size: 26px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap {
    gap: 10px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWHead {
    font-size: 18px;
  }
  .Landing #SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWBody {
    font-size: 18px;
  }

  .OctagonLogo {
    width: 25vw !important;
    height: 4vw !important;
  }

  .ContentBlocks {
    padding-top: 4vw;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 2vw 6vw;
  }

  .Navbar {
    padding: 6vw;
  }
  .Navbar > .NavbarBlock:nth-child(2) {
    height: auto;
    margin: auto 0%;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeaderList) {
    font-size: 4vw;
    line-height: 4vw;
    margin-top: 4vw;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeader) {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  .ButtonGetProjectList {
    font-size: 1.6vw;
    line-height: 2vw;

    margin-top: 3vw;
  }

  #ImgLogo {
    background-position: calc(50% - (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50% + (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }

  #ImgLine {
    background-position: 50% calc(50% - 18vw);
    background-size: 5vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 40vw) calc(50% - 18vw);
    background-size: 3vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 40.6vw) calc(50% - 16.8vw);
    background-size: 3vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 3vw) calc(50% + 14.5vw);
    background-size: 2.6vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 2.5vw) calc(50% + 15.5vw);
    background-size: 2.6vw;
  }

  #ImgPlus {
    background-position: calc(50% + 42vw) calc(50% + 18vw);
    background-size: 2.4vw;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) {
    margin-bottom: 2vw;
  }
  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 150px;
    background-size: contain;
  }
  .ContentTitle {
    position: relative;
    width: auto;
    font-size: 4vw !important;
    line-height: 4vw !important;

    justify-content: center;
  }

  .Benefits {
    padding: 0% 0%;
    margin: auto;
    gap: 1vw;
  }

  .AutoMarginWidth > *:nth-last-child(1) {
    margin-left: 35px !important;
    margin-right: auto !important;
  }
  .AutoMarginWidth > *:nth-child(1) {
    margin-left: auto !important;
    margin-right: 35px !important;
  }
  .ContentBlockFooter {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .ContentFooter {
    position: relative;

    display: flex;
    justify-content: center;

    padding: 60px 0%;
    background: rgba(30, 30, 32, 0.5);
  }
  #LandingWrapper > .ContentFooter > * {
    margin: 0% 35px !important;
  }

  .Navbar > .NavbarBlock:nth-child(2) > a {
    font-size: 1.2vw;
    font-weight: 1.2vw;
  }
}

@media (min-width: 1400px) {
  #LandingWrapper {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  #LandingWrapper > .Landing {
    min-width: 1000px;
    max-width: 1400px;

    margin: 0% auto;
  }

  .OctagonLogo {
    width: 294px;
    height: 52.09px;
  }

  .ContentBlocks {
    padding-top: 30px;
  }

  .ContentBlocks > .ContentBlock:nth-child(1) {
    margin-bottom: 0px;
  }

  .ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
    height: 760px;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeaderList) {
    font-size: 50px;
    line-height: 54px;
    margin-top: 100px;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p:not(.ContentHeader) {
    font-size: 21px;
    line-height: 30px;
  }

  .Landing
    .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > a.ButtonFooterGradientInside {
    margin: 30px auto 20px 0% !important;
  }

  #ImgLogo {
    background-position: calc(50% - (521px) / 1.8) calc(50px);
    background-size: 521px;
  }
  #ImgLogoRectangle {
    background-position: calc(50% + (521px) / 1.8) calc(30px);
    background-size: 521px;
  }

  #ImgLine {
    background-position: 50% calc(40px);
    background-size: 95px auto;
  }

  #ImgCircle {
    background-position: calc(50% + ((40px) * 11) + 66px) calc(0px);
    background-size: 40px;
  }
  #ImgCircleDotted {
    background-position: calc(50% + ((40px) * 11) + 86px) calc(16px);
    background-size: 40px;
  }

  #ImgTriangle {
    background-position: calc(50% - 20px) calc(500px);
    background-size: 73px;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 10px) calc(530px);
    background-size: 73px;
  }

  #ImgPlus {
    background-position: calc(50% + ((40px) * 11) + 66px) calc(530px);
    background-size: 33.3px;
  }

  #ImgBlur1 {
    background-size: 1150px;
  }
  #ImgBlur2 {
    background-position: 100% 0px;
    background-size: 1250px;
  }
  #ImgBlur3 {
    background-size: 550px;
  }
  #ImgBlur4 {
    background-size: 850px;
  }

  .AutoMarginWidth > *:nth-last-child(1) {
    margin-left: 35px;
    margin-right: auto;
  }
  .AutoMarginWidth > *:nth-child(1) {
    margin-left: auto;
    margin-right: 35px;
  }
}
</style>

<style>
#app > #LandingWrapper > .Landing .FormData .el-form-item__label {
  color: #fff;
}
</style>

<style scoped>
.BurgerMenuButtons {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: auto;

  padding: 20px 20px 12vw 20px;

  background-color: rgb(20, 20, 20);
}

.BurgerMenuButtons > div.OctagonLogo {
  margin: 4vw auto 8vw auto !important;
}

.BurgerMenuButtons > a.ButtonLink {
  margin: 1vw auto !important;
  font-size: 4vw;
}

.BurgerMenuButtons a.ButtonLink:nth-child(4) {
  margin-bottom: 8vw !important;
}

.BurgerMenuButtons a.ButtonFooter {
  margin: 2vw auto !important;
  width: 60vw !important;
}
</style>

<style scoped>
.PartnerImagePhoto {
  position: relative;
  display: block;

  width: 200px;
  height: 135px;
}

.PI_1C {
  background: url("./../assets/img/Landing/PartnersImages/1C.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_MOIO {
  background: url("./../assets/img/Landing/PartnersImages/minobr_irk_obl.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_IRNITU {
  background: url("./../assets/img/Landing/PartnersImages/irnitu.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_MICCIFRI {
  background: url("./../assets/img/Landing/PartnersImages/mincifri.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_OBLASTMOLODIH {
  background: url("./../assets/img/Landing/PartnersImages/oblast_molodih.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

#PartnersBlock {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 90px 0%;

  margin-bottom: 100px;

  background-color: white;
}
#PartnersBlock > .FullWidthBlock {
  position: absolute;

  width: 100vw;
  height: 100%;

  margin: 0% !important;

  top: 0%;
  left: calc((100vw - 100%) * -1 / 2);

  background-color: white;

  z-index: 1;
}

#PartnersBlock > * {
  z-index: 2;
}

#PartnersBlock > p {
  color: #1d1a42;
}

.ImagesBlock {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 0% 34px;
}

#OctagonToday {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 90px 0%;

  margin-bottom: 100px;
}
#OctagonToday > .OTBlocks {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}
#OctagonToday > .OTBlocks > .OTBlock {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: auto;

  flex-shrink: 1;
}
#OctagonToday > .OTBlocks > .OTBlock > .OTBHead {
  position: relative;
  display: block;

  width: 48px;
  height: 46px;

  background: url("./../assets/img/Landing/PartnersImages/Star.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  flex-shrink: 0;
}
#OctagonToday > .OTBlocks > .OTBlock > .OTBText {
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
}

#SuccessfulCases {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 90px 0%;

  margin-bottom: 100px;
}

#SuccessfulCases > .CasesListContainer {
  position: relative;

  width: 100%;
  height: 750px;
}

#SuccessfulCases > .CasesListContainer > .CasesListWrapper {
  position: absolute;

  width: 100vw;
  height: 100%;

  margin: 0% !important;

  top: 0%;
  left: calc((100vw - 100%) * -1 / 2);

  z-index: 1;

  overflow: hidden;
}

#SuccessfulCases > .CasesListContainer > .CasesListWrapper > .CasesList {
  position: relative;

  width: 100%;
  height: 100%;

  padding: 0% 50px 8px 50px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  overflow-x: auto;
  overflow-y: hidden;
}

#SuccessfulCases
  > .CasesListContainer
  > .CasesListWrapper
  > .CasesList
  > *:nth-child(1) {
  margin-left: auto;
}
#SuccessfulCases
  > .CasesListContainer
  > .CasesListWrapper
  > .CasesList
  > *:nth-last-child(1) {
  margin-right: auto;
}

#SuccessfulCases .CasesList > .CaseBlock {
  position: relative;

  padding: 30px;

  width: 590px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  border-radius: 10px;
  background: linear-gradient(
      119deg,
      rgba(68, 0, 255, 0.1) 25.73%,
      rgba(29, 26, 66, 0.1) 57.17%,
      rgba(68, 0, 255, 0.1) 100%
    ),
    #1d1a42;

  flex-shrink: 0;
}

#SuccessfulCases .CasesList > .CaseBlock > .CBTitle {
  color: #fff;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}
#SuccessfulCases .CasesList > .CaseBlock > .CBWrap {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
#SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWHead {
  background: var(
    --icons-purple,
    linear-gradient(340deg, #ab64f5 -44.29%, #62dbf6 179.47%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 200% */
}
#SuccessfulCases .CasesList > .CaseBlock > .CBWrap > .CBWBody {
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 125% */
}
</style>
