<template>
  <div>
    <LandingNavbar v-if="!user" />
    <ProfileNavbar v-else class="AdaptiveForNewNavbar" />
    <div class="HiddentProjectsList">
      <div class="ProjectListHeader">
        <h3>Список доступных проектов на нашем сайте</h3>
      </div>
      <div class="ProjectsList">
        <ProjectList
          :SelectProject="() => {}"
          :NeedButton="'none'"
          :checkClosedProjects="'none'"
        ></ProjectList>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectList from "@/components/Project/ProjectsList";
import ProfileNavbar from "@/components/Navbar/NavbarCombined.vue";
import LandingNavbar from "@/components/Landing/Navbar";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { ProjectList, LandingNavbar, ProfileNavbar },
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
};
</script>

<style scoped>
.AdaptiveForNewNavbar {
  padding: 50px 120px 32px 120px;
}

@media (max-width: 480px) {
  .AdaptiveForNewNavbar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 20px 32px 20px;
  }
}
@media (min-width: 480px) and (max-width: 991px) {
  .AdaptiveForNewNavbar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 40px 32px 40px;
  }
}

.HiddentProjectsList {
  position: relative;

  left: 0px;

  width: 80vw;
  height: auto;

  margin: 70px auto 0% auto;
  padding: 1% 3% 0% 3%;

  display: flex;
  justify-content: center;

  flex-direction: column;
}

.ProjectListHeader {
  position: relative;

  width: 100%;
  height: auto;

  margin: 1vh 0%;

  display: flex;

  justify-content: center;
}

.ProjectsList {
  position: relative;

  width: 100%;
  height: 100vh;

  padding: 0% 0%;

  overflow-y: scroll;
}
</style>

<style>
.footer > .row > div:nth-child(1) {
  opacity: 0;
}
</style>
